import React from "react"
import Layout from "@components/Layout/hoc/withDatoData"
import Helmet from "@components/Helmet"
import DatoContent from "@dato/DatoContent"
import { renderMetaTags } from "react-datocms"

const Page: any = ({ data, module = "automotive" }) => {
  console.log(data.homePage.contentPowersport)
  return (
    <Layout module={module}>
      <Helmet
        title="TMI Products"
        meta={renderMetaTags(data.homePage?._seoMetaTags)}
      />
      {data?.homePage &&
        (module && module == "powersports" ? (
          <DatoContent
            module={module}
            content={data.homePage.contentPowersport}
          />
        ) : (
          <DatoContent module={module} content={data.homePage.content} />
        ))}
    </Layout>
  )
}

export default Page
